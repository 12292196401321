import React from 'react'
import { useRouter } from 'next/router'

import { THEME, getBrandFeedOptions } from 'utils'
import { useStore } from 'hooks'
import { BrandsStore } from 'stores/brands'

import MediaQuery from 'components/MediaQuery'
import SubscribeButton from './SubscribeButton'
import Twitter from 'icons/Twitter'
import Instagram from 'icons/Instagram'
import TikTok from 'icons/TikTok'
import FeedNavigation from 'components/FeedNavigation'

const DROPDOWN_URLS = {
  amazon: {
    text: 'Amazon Music',
    image: 'amazon-music.png'
  },
  itunes: {
    text: 'Apple Podcasts',
    image: 'apple-podcasts.svg'
  },
  google: {
    text: 'Google Podcasts',
    image: 'google-podcasts.png'
  },
  iheart: {
    text: 'iHeart'
  },
  pandora: {
    text: 'Pandora'
  },
  spotify: {
    text: 'Spotify',
    image: 'spotify.svg'
  },
  stitcher: {
    text: 'Stitcher'
  },
  tunein: {
    text: 'TuneIn'
  }
}

export default function BrandDetailHeader() {
  const router = useRouter()
  const brandsStore = useStore(BrandsStore)

  if (!brandsStore.current) return null

  const brand = brandsStore.current
  const activeUrlPath = router.asPath?.split('?')[0]
  const foregroundColor = brand.colors?.secondary || '#ffffff'

  const subscribeDropdownList = []

  for (const [key, value] of Object.entries(brand.display_on_website)) {
    if (value)
      subscribeDropdownList.push({
        text: DROPDOWN_URLS[key]?.text,
        url: brand.social[key],
        image: `/static/images/${DROPDOWN_URLS[key]?.image ?? `${key}.png`}`
      })
  }

  return (
    <>
      <div className='brandHeader'>
        <div className='headerContent'>
          <div className='headerText'>
            <div>
              <div className='title'>{brand.name}</div>
              <div className='tagline'>{brand.tagline}</div>
            </div>
            <div className='socialLinks'>
              {subscribeDropdownList.length > 0 && (
                <span>
                  <SubscribeButton
                    color={foregroundColor}
                    dropdownList={subscribeDropdownList.sort(({ text: aText }, { text: bText }) =>
                      aText.localeCompare(bText)
                    )}
                  />
                </span>
              )}
              <MediaQuery minWidth={THEME.BREAKPOINTS.XS}>
                {brand.social.twitter ? (
                  <a target='_blank' rel='noreferrer noopener' href={brand.social.twitter.url}>
                    <Twitter color={foregroundColor} height='28px' width='28px' />
                  </a>
                ) : null}
                {brand.social.instagram ? (
                  <a target='_blank' rel='noreferrer noopener' href={brand.social.instagram.url}>
                    <Instagram color={foregroundColor} height='28px' width='28px' />
                  </a>
                ) : null}
                {brand.social.tiktok ? (
                  <a target='_blank' rel='noreferrer noopener' href={brand.social.tiktok.url}>
                    <TikTok height='28px' width='auto' fill={foregroundColor} />
                  </a>
                ) : null}
              </MediaQuery>
            </div>
          </div>
          <div className='headerImage' />
        </div>
        <div className='subnavbar'>
          <FeedNavigation navOptions={getBrandFeedOptions(brand, activeUrlPath)} />
        </div>
      </div>
      <style jsx>{`
        .brandHeader {
          width: 100%;
          margin-top: -20px;
          background-color: ${brand.colors.primary};
        }
        .headerContent {
          max-width: 1200px;
          height: 200px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
        }
        .headerText {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          bottom: 0;
          width: 100%;
          z-index: 2;
          padding: 16px;
        }
        .headerText .title {
          color: ${foregroundColor};
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 34px;
          text-transform: uppercase;
        }
        .headerText .tagline {
          font-size: 12px;
          font-weight: bold;
          color: ${brand.colors.secondary || '#ffffff'};
        }
        .headerImage {
          width: 100%;
          height: 100%;
          opacity: 0.3;
          background-size: cover;
          background-position: center;
          position: relative;
          background-image: url(${brand.cover_photo});
        }
        .socialLinks {
          padding-top: 28px;
          display: flex;
          align-items: center;
        }
        .socialLinks > span,
        .socialLinks > a {
          padding-right: 16px;
        }
        .subnavbar {
          background: #ffffff;
          border-bottom: 1px solid #ddd;
          margin-bottom: 16px;
        }
        .subnavbar a {
          font-size: 1.25rem !important;
        }
        .subnavbarContent {
          max-width: 1200px;
          height: 100%;
          margin: 0 auto;
        }
        .subnavbarContent a {
          text-transform: uppercase;
          color: #777777;
          font-size: 16px;
          font-weight: bold;
          padding: 12px 0;
          margin: 0 12px;
          display: inline-block;
        }
        .subnavbarContent a.active {
          color: #333333;
          border-bottom: 4px solid ${THEME.COLORS.PRIMARY};
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .brandHeader {
            margin-top: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .headerContent {
            height: 380px;
          }
          .headerText {
            align-items: flex-start;
          }
          .headerText .title {
            font-size: 52px;
          }
          .headerText .tagline {
            font-size: 16px;
          }
          .subnavbarContent a {
            font-size: 18px;
            padding: 16px 0;
            margin: 0 16px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .headerImage {
            width: 65%;
            opacity: 1;
          }
          .headerContent {
            position: initial;
          }
          .headerText {
            padding: 96px 16px 16px;
            width: 35%;
            position: initial;
            flex-direction: column;
            justify-content: initial;
          }
        }
      `}</style>
    </>
  )
}
