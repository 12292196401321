import React, { useState } from 'react'
import Arrow from 'icons/Arrow'
import { showHeaderEvents } from 'lib/ga'

const SubscribeButton = ({ dropdownList, color }) => {
  const [isOpen, setOpen] = useState(false)
  if (dropdownList.length === 0) return null
  return (
    <div
      className='subscribeButtonContainer'
      onClick={() => setOpen(!isOpen)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <button>
        <span>SUBSCRIBE</span>
        <span className='arrow'>
          <Arrow
            direction='down'
            color={color}
            size='10px'
            style={{ transform: isOpen ? undefined : 'rotate(180deg)' }}
          />
        </span>
      </button>
      {isOpen && <div className='spacer' />}
      {isOpen && (
        <div className='dropdown'>
          {dropdownList.map((item) => (
            <a
              href={item.url}
              target='_blank'
              rel='noopener noreferrer'
              key={item.text}
              className='dropdownItem'
              onClick={() => {
                showHeaderEvents.podcast(item.text)
              }}
            >
              <img className={item.text === 'Amazon Music' && 'amazon-music-logo'} src={item.image} alt={item.text} />
              <span>{item.text}</span>
            </a>
          ))}
        </div>
      )}
      <style jsx>{`
        .subscribeButtonContainer {
          position: relative;
        }
        button {
          margin: 0;
          font-size: 16px;
          color: ${color};
          font-weight: bold;
          width: auto;
          padding: 10px 20px;
          border: 2px solid ${color};
          display: flex;
          align-items: center;
        }
        .arrow {
          padding-left: 8px;
        }
        .spacer {
          position: absolute;
          top: 40px;
          min-width: 200px;
          width: auto;
          height: 8px;
        }
        .dropdown {
          top: 48px;
          min-width: 200px;
          width: auto;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          background: white;
          position: absolute;
          display: flex;
          flex-direction: column;
        }
        .dropdownItem {
          font-size: 14px;
          color: #333;
          font-weight: bold;
          display: flex;
          align-items: center;
          padding: 6px;
          border-bottom: 2px solid #ddd;
          border-radius: 4px;
          transition: all cubic-bezier(0.5, 0, 0.5, 1) 150ms;
        }
        .dropdownItem:hover {
          background-color: #f8f8f8;
        }
        .dropdownItem:last-of-type {
          border: none;
        }
        .dropdownItem span {
          padding-left: 8px;
        }
        img {
          max-width: 30px;
        }
        .amazon-music-logo {
          border-radius: 6px;
        }
      `}</style>
    </div>
  )
}

export default SubscribeButton
